import React, { Component } from "react";
import { Image, Container, Row, Col } from "react-bootstrap";
import MK23 from "../../img/MK23gp.jpg";
import MK22 from "../../img/MK22gp.jpg";
import MK21 from "../../img/MK21.jpg";
import MK20 from "../../img/MK20GP2.jpg";
import MK19 from "../../img/MK19.jpg";
import MK18 from "../../img/MK18.jpg";
import MK17 from "../../img/MK17.jpg";
import MK16 from "../../img/MK16.jpg";
import MK15 from "../../img/MK15.jpg";
import MK14 from "../../img/MK14.jpg";
import MK13 from "../../img/MK13.jpg";
import MK12 from "../../img/MK12.jpg";
import MK11 from "../../img/MK11.jpg";
import MK10 from "../../img/MK10.jpg";
import MK09 from "../../img/MK09.jpg";
import MK08 from "../../img/MK08.jpg";
import MK07 from "../../img/MK07.jpg";
import MK06 from "../../img/MK06.jpg";
import MK05 from "../../img/MK05.jpg";
import REFLX from "../../img/reflX.png";
import { titel, text1, text2 } from "../../style.js";

class Pateter extends Component {
  render() {
    return (
      <Container style={paddingStyle}>
        <Row lg={2} md={2} sm={1} xs={1} style={{ marginTop: "50px" }}>
          <Col>
            <div>
              <h1 style={titel}>ReflX</h1>
              <div style={text2}>
                Efter att ha suttit ett år i MK och arrangerat den bästa
                Mottagningen någonsin så övergår man till att bli patet. MKs
                pateter kallas för ReflX.
              </div>
              <div style={text2}>
                Pateter är alltid bra att ha, de sitter på mycket bra kunskap
                och är duktiga på att bolla nya idéer med.
              </div>
            </div>
          </Col>
          <Col>
            <Image src={REFLX} style={imgStyle} alt={""}></Image>
          </Col>
        </Row>


        <Row style={paddingStyle}>
          <div style={text1}>MK23</div>
        </Row>
        <Row style={divider}></Row>
        <Row>
          <Image src={MK23} style={imgStyle2} alt={""}></Image>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Ordförande</Col>
          <Col style={rightText}>Marcus "Surdeg" Johansson</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Vice ordförande</Col>
          <Col style={rightText}>Siri "Kronan" Moberg</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Redaktör</Col>
          <Col style={rightText}>Alexander "Sputnik" Persson</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>PR</Col>
          <Col style={rightText}>Daði "Klakinn" Andrason</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Arrangör</Col>
          <Col style={rightText}>Elin "Lo" Ekstedt</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Organisatör</Col>
          <Col style={rightText}>Rasmus "Røzzø" Johansson</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Instruktör</Col>
          <Col style={rightText}>William "Milo" Nordkvist</Col>
        </Row>



        <Row style={paddingStyle}>
          <div style={text1}>MK22</div>
        </Row>
        <Row style={divider}></Row>
        <Row>
          <Image src={MK22} style={imgStyle2} alt={""}></Image>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Ordförande</Col>
          <Col style={rightText}>Eimer "Florett" Ahlstedt</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Vice ordförande</Col>
          <Col style={rightText}>Emrik "Sharpie" Östling</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Redaktör</Col>
          <Col style={rightText}>Malin "Jägarn" Johansson</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>PR</Col>
          <Col style={rightText}>Felicia "TIM" Andersson</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Arrangör</Col>
          <Col style={rightText}>Erik "Smulan" Brusewitz</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Organisatör</Col>
          <Col style={rightText}>Joar "Bägg" Walton</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Instruktör</Col>
          <Col style={rightText}>Sebastian "Kvalle" Kvaldén</Col>
        </Row>

        <Row style={paddingStyle}>
          <div style={text1}>MK21</div>
        </Row>
        <Row style={divider}></Row>
        <Row>
          <Image src={MK21} style={imgStyle2} alt={""}></Image>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Ordförande</Col>
          <Col style={rightText}>Axel "Webers" Westberg</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Vice ordförande</Col>
          <Col style={rightText}>Måns "Mums" Josefsson</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Redaktör</Col>
          <Col style={rightText}>Martin "Nugget" Hagmar</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>PR</Col>
          <Col style={rightText}>Wilhelm "Svan" Svanlund</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Arrangör</Col>
          <Col style={rightText}>Isabell "BB" Andersson</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Organisatör</Col>
          <Col style={rightText}>Felix "Heinz" Holmesten</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Instruktör</Col>
          <Col style={rightText}>Edvard "Heinz" Heinmetz</Col>
        </Row>

        <Row style={paddingStyle}>
          <div style={text1}>MK20</div>
        </Row>
        <Row style={divider}></Row>
        <Row>
          <Image src={MK20} style={imgStyle2} alt={""}></Image>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Ordförande</Col>
          <Col style={rightText}>Linnéa "Prim" Bark</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Vice ordförande</Col>
          <Col style={rightText}>Oskar "Bambi" Thune</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Redaktör</Col>
          <Col style={rightText}>Åke "K" Axeland</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>PR</Col>
          <Col style={rightText}>Ellen "Leo" Bensköld</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Arrangör</Col>
          <Col style={rightText}>Carl "Uber" Wiede</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Organisatör</Col>
          <Col style={rightText}>Samuel "Soul" Jönsson</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Instruktör</Col>
          <Col style={rightText}>Pär "Giff" Aronsson</Col>
        </Row>

        <Row style={paddingStyle}>
          <div style={text1}>MK19</div>
        </Row>
        <Row style={divider}></Row>
        <Row>
          <Image src={MK19} style={imgStyle2} alt={""}></Image>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Ordförande</Col>
          <Col style={rightText}>Nils "Netzflix" Netz</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Vice ordförande</Col>
          <Col style={rightText}>Erik "Lövet" Söderstadius</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Redaktör</Col>
          <Col style={rightText}>Max "Lil'Max" Liljeqvist</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>PR</Col>
          <Col style={rightText}>Cecilia "Neon" Lewenstedt</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Arrangör</Col>
          <Col style={rightText}>Marcus "Mackan" Mellqvist</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Organisatör</Col>
          <Col style={rightText}>Jonas "Coca" Hedlund</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Instruktör</Col>
          <Col style={rightText}>Josenfin "Alt" Nilsson</Col>
        </Row>

        <Row style={paddingStyle}>
          <div style={text1}>MK18</div>
        </Row>
        <Row style={divider}></Row>
        <Row>
          <Image src={MK18} style={imgStyle2} alt={""}></Image>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Ordförande</Col>
          <Col style={rightText}>Johanna "Jojo" Stenmark</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Vice ordförande</Col>
          <Col style={rightText}>David "Honing" Larsson</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Redaktör</Col>
          <Col style={rightText}>Jakob "Jakob" Aasa</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>PR</Col>
          <Col style={rightText}>Fanny "Fanta" Apelgren</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Arrangör</Col>
          <Col style={rightText}>Oskar "Gurkan" Sjökvist</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Organisatör</Col>
          <Col style={rightText}>Virena "Virre" Nassif</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Instruktör</Col>
          <Col style={rightText}>Neda "Puzzl" Farhand</Col>
        </Row>

        <Row style={paddingStyle}>
          <div style={text1}>MK17</div>
        </Row>
        <Row style={divider}></Row>
        <Row>
          <Image src={MK17} style={imgStyle2} alt={""}></Image>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Ordförande</Col>
          <Col style={rightText}>Dennis "Peking" Zorko</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Vice ordförande</Col>
          <Col style={rightText}>Lina "Hallon" Olandersson</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Redaktör</Col>
          <Col style={rightText}>Ludvig "Ludd/Frej" Ekman</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>PR</Col>
          <Col style={rightText}>Jakob "Någon" Laurell</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Arrangör</Col>
          <Col style={rightText}>Anna "Ananas" Petersson</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Organisatör</Col>
          <Col style={rightText}>Emma "Mumin" Hannele Lamberg</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Instruktör</Col>
          <Col style={rightText}>Maria "Pluto" Rundberg</Col>
        </Row>

        <Row style={paddingStyle}>
          <div style={text1}>MK16</div>
        </Row>
        <Row style={divider}></Row>
        <Row>
          <Image src={MK16} style={imgStyle2} alt={""}></Image>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Ordförande</Col>
          <Col style={rightText}>Fanny "Fenix" Viksten</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Vice ordförande</Col>
          <Col style={rightText}>Anna "Chikki" Nylander</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Redaktör</Col>
          <Col style={rightText}>Robin "Front" Front</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>PR</Col>
          <Col style={rightText}>Malin "Mango" Lilja</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Arrangör</Col>
          <Col style={rightText}>Petter "Utter" Häggberg</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Organisatör</Col>
          <Col style={rightText}>Ida "Kotten" Pettersson</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Instruktör</Col>
          <Col style={rightText}>Lukas "Lugge" Hansen</Col>
        </Row>

        <Row style={paddingStyle}>
          <div style={text1}>MK15</div>
        </Row>
        <Row style={divider}></Row>
        <Row>
          <Image src={MK15} style={imgStyle2} alt={""}></Image>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Ordförande</Col>
          <Col style={rightText}>Navid "Målsman" Haddad</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Vice ordförande</Col>
          <Col style={rightText}>Caroline "Brave" Matsson</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Redaktör</Col>
          <Col style={rightText}>Simon "siMs0n" Nielsen</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>PR</Col>
          <Col style={rightText}>Alvina "Alven" Westling</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Arrangör</Col>
          <Col style={rightText}>Fanny "dB" Johansson</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Organisatör</Col>
          <Col style={rightText}>Amanda "Mini" Markgren</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Instruktör</Col>
          <Col style={rightText}>Gustav "Gürra" Eriksson</Col>
        </Row>

        <Row style={paddingStyle}>
          <div style={text1}>MK14</div>
        </Row>
        <Row style={divider}></Row>
        <Row>
          <Image src={MK14} style={imgStyle2} alt={""}></Image>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Ordförande</Col>
          <Col style={rightText}>Lea "Lea" Jerre</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Vice ordförande</Col>
          <Col style={rightText}>Christoffer "ChrllX" Sandlund</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Redaktör</Col>
          <Col style={rightText}>William "Moppen" Dahlberg</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>PR</Col>
          <Col style={rightText}>Danny "Lam(m)" Lam</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Arrangör</Col>
          <Col style={rightText}>Susann "Sazu" Götharson</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Organisatör</Col>
          <Col style={rightText}>Jonathan "Carbol" Carbol</Col>
        </Row>

        <Row style={paddingStyle}>
          <div style={text1}>MK13</div>
        </Row>
        <Row style={divider}></Row>
        <Row>
          <Image src={MK13} style={imgStyle2} alt={""}></Image>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Ordförande</Col>
          <Col style={rightText}>Lena "Flower" Mårtensson</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Vice ordförande</Col>
          <Col style={rightText}>Oscar "Zemi" Johansson</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Redaktör</Col>
          <Col style={rightText}>Elin "E.T." Alexandersson</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>PR</Col>
          <Col style={rightText}>Emma Hermansson</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Arrangör</Col>
          <Col style={rightText}>Rasmus "Barile" Åberg</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Organisatör</Col>
          <Col style={rightText}>Sofia "Braxen" Andersson</Col>
        </Row>

        <Row style={paddingStyle}>
          <div style={text1}>MK12</div>
        </Row>
        <Row style={divider}></Row>
        <Row>
          <Image src={MK12} style={imgStyle2} alt={""}></Image>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Ordförande</Col>
          <Col style={rightText}>Pontus "Pyssling" Eliassion</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Vice ordförande</Col>
          <Col style={rightText}>Niklas Wärvik</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Redaktör</Col>
          <Col style={rightText}>Anna "Zhip" Landström</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>PR</Col>
          <Col style={rightText}>Tove "Tudor" Larsson</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Arrangör</Col>
          <Col style={rightText}>Madeliene "Snixx" Sköld</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Organisatör</Col>
          <Col style={rightText}>Anna-Karin "AK" Löfman</Col>
        </Row>

        <Row style={paddingStyle}>
          <div style={text1}>MK11</div>
        </Row>
        <Row style={divider}></Row>
        <Row>
          <Image src={MK11} style={imgStyle2} alt={""}></Image>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Ordförande</Col>
          <Col style={rightText}>Johannes "Yoshi" Schygge</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Vice ordförande</Col>
          <Col style={rightText}>Markus "_us" Pettersson</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Redaktör</Col>
          <Col style={rightText}>Gunilla "Gilla" Wentzel</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>PR</Col>
          <Col style={rightText}>Madeleine "Madde" Czarnecki</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Arrangör</Col>
          <Col style={rightText}>Cathrine "Cath" Kempe</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Organisatör</Col>
          <Col style={rightText}>Oscar "poscar" Söderlund</Col>
        </Row>

        <Row style={paddingStyle}>
          <div style={text1}>MK10</div>
        </Row>
        <Row style={divider}></Row>
        <Row>
          <Image src={MK10} style={imgStyle2} alt={""}></Image>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Ordförande</Col>
          <Col style={rightText}>David Sundelius</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Vice ordförande</Col>
          <Col style={rightText}>Fredrik "Freddie" Axelsson</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Redaktör</Col>
          <Col style={rightText}>Thomas "Teddy" Berntsson</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>PR</Col>
          <Col style={rightText}>Johanna "Jinn" Ivarsson</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Arrangör</Col>
          <Col style={rightText}>Henrik "Henri" Ohlsson</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Organisatör</Col>
          <Col style={rightText}>My "Mysan" Klint</Col>
        </Row>

        <Row style={paddingStyle}>
          <div style={text1}>MK09</div>
        </Row>
        <Row style={divider}></Row>
        <Row>
          <Image src={MK09} style={imgStyle2} alt={""}></Image>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Ordförande</Col>
          <Col style={rightText}>Martin Petterson</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Vice ordförande</Col>
          <Col style={rightText}>Kim Olander</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Redaktör</Col>
          <Col style={rightText}>Isa Nordqvist</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>PR</Col>
          <Col style={rightText}>Simon Olin</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Arrangör</Col>
          <Col style={rightText}>Maria "Ernis" Ernfors</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Organisatör</Col>
          <Col style={rightText}>Kalle "Kal" Bryntesson</Col>
        </Row>

        <Row style={paddingStyle}>
          <div style={text1}>MK08</div>
        </Row>
        <Row style={divider}></Row>
        <Row>
          <Image src={MK08} style={imgStyle2} alt={""}></Image>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Ordförande</Col>
          <Col style={rightText}>Jöran Marquardt</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Sekreterare</Col>
          <Col style={rightText}>Joen Peter</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Redaktör</Col>
          <Col style={rightText}>Therese Ericsson</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>PR</Col>
          <Col style={rightText}>Hanna "Aeg" Krönlein</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>GÖK, Götaplatsen och Kassör</Col>
          <Col style={rightText}>Susanna "Suss" Lidman</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>RunAn</Col>
          <Col style={rightText}>Carolin Christiansson</Col>
        </Row>

        <Row style={paddingStyle}>
          <div style={text1}>MK07</div>
        </Row>
        <Row style={divider}></Row>
        <Row>
          <Image src={MK07} style={imgStyle2} alt={""}></Image>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Ordförande</Col>
          <Col style={rightText}>Per Löfqvist</Col>
        </Row>

        <Row style={table}>
          <Col style={leftText}>Redaktör</Col>
          <Col style={rightText}>Carolin Jansson</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Kassör, Kalas och Kommunikation</Col>
          <Col style={rightText}>Carin Jobson Sellström</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Götaplatsen</Col>
          <Col style={rightText}>Mikaela Börjesson</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Liseberg</Col>
          <Col style={rightText}>Jenny Andersson</Col>
        </Row>

        <Row style={paddingStyle}>
          <div style={text1}>MK06</div>
        </Row>
        <Row style={divider}></Row>
        <Row>
          <Image src={MK06} style={imgStyle2} alt={""}></Image>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Ordförande</Col>
          <Col style={rightText}>Oskar Luvö</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Kassör</Col>
          <Col style={rightText}>Magnus Våge</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Redaktör</Col>
          <Col style={rightText}>Kristin Kullberg</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Sekreterare</Col>
          <Col style={rightText}>Sabina Friberg</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>KK, Kalas och Kommunikation</Col>
          <Col style={rightText}>Andreas Öhrman</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Götaplatsen</Col>
          <Col style={rightText}>Marika Cederblad</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Lisebersansvarig</Col>
          <Col style={rightText}>Magnus Hammarström</Col>
        </Row>

        <Row style={paddingStyle}>
          <div style={text1}>MK05</div>
        </Row>
        <Row style={divider}></Row>
        <Row>
          <Image src={MK05} style={imgStyle2} alt={""}></Image>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Ordförande</Col>
          <Col style={rightText}>Maaria Bing</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Vice ordförande</Col>
          <Col style={rightText}>Jonas Magazinius</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Redaktör</Col>
          <Col style={rightText}>Daniel Pettersson</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Götaplatsenansvarig</Col>
          <Col style={rightText}>Joni Paananen</Col>
        </Row>
        <Row style={table}>
          <Col style={leftText}>Lisebergshallsansvarig</Col>
          <Col style={rightText}>Andreas Eliasson</Col>
        </Row>
      </Container>
    );
  }
}

const paddingStyle = {
  marginTop: "50px",
};

const table = {
  borderBottom: "1px dashed",
  borderColor: "rgba(1,1,1,0.3)",
  marginLeft: "10%",
  marginRight: "10%",
  padding: "0",
};

const leftText = {
  left: "0",
  padding: "0",
  fontWeight: "bold",
};
const rightText = {
  textAlign: "right",
  padding: "0",
};

const imgStyle = {
  width: "50%",
  borderRadius: "1%",
  display: "flex",
  justifyContent: "right",
  float: "right",
  marginRight: "30%",
  marginTop: "10%",
};
const imgStyle2 = {
  width: "80%",
  height: "80%",
  borderRadius: "1%",
  marginLeft: "50%",
  transform: "translate(-50%)",
  marginBottom: "30px",
};
const divider = {
  backgroundColor: "black",
  width: "100%",
  height: "2px",
  marginBottom: "30px",
  display: "flex",
  justifyContent: "center",
};

export default Pateter;
