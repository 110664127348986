import React, { Component } from "react";
import { Navbar, Nav, Container, Image, NavbarBrand } from "react-bootstrap";
import Logo from "../../img/mklogowithbackground.png";

class Header extends Component {
  render() {
    let width = window.innerWidth;
    if (width < 600) {
      return (
        <div>
          <Navbar
            fixed={"top"}
            style={style.header}
            variant="dark"
            expand={"lg"}
          >
            <Container style={smallLogo}>
              <Image roundedCircle src={Logo} style={style.logoImg} />
            </Container>
            <NavbarBrand href="/" style={{ float: "right" }}>
              <div style={smallHeaderText}>MOTTAGNINGSKOMMITTÉN</div>
            </NavbarBrand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Link style={style.navLink} href="/">
                  HEM
                </Nav.Link>
                {/*<Nav.Link style={style.navLink}  href="/first_day">FÖRSTA DAGEN</Nav.Link>*/}
                <Nav.Link style={style.navLink} href="/arrangemang">
                  ARRANGEMANG
                </Nav.Link>
                <Nav.Link style={style.navLink} href="/about">
                  OM MK
                </Nav.Link>
                <Nav.Link style={style.navLink} href="/NollK">
                  NOLLK
                </Nav.Link>
                <Nav.Link style={style.navLink} href="/aspa">
                  SÖK MK
                </Nav.Link>
                <Nav.Link style={style.navLink} href="/kontakt">
                  FÖR FÖRETAG
                </Nav.Link>
                {/* <Nav.Link style={style.navLink} href="/Pateter">PATETER</Nav.Link> */}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      );
    } else {
      return (
        <div>
          <Navbar
            fixed={"top"}
            style={style.header}
            variant="dark"
            expand={"lg"}
          >
            <Container style={style.logo}>
              <Image roundedCircle src={Logo} style={style.logoImg} />
            </Container>
            <NavbarBrand href="/" style={{ marginLeft: "70px" }}>
              <div style={headerText}>MOTTAGNINGSKOMMITTÉN</div>
            </NavbarBrand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Link style={style.navLink} href="/">
                  HEM
                </Nav.Link>
                {/*<Nav.Link style={style.navLink}  href="/first_day">FÖRSTA DAGEN</Nav.Link>*/}
                <Nav.Link style={style.navLink} href="/arrangemang">
                  ARRANGEMANG
                </Nav.Link>
                <Nav.Link style={style.navLink} href="/about">
                  OM MK
                </Nav.Link>
                <Nav.Link style={style.navLink} href="/NollK">
                  NOLLK
                </Nav.Link>
                <Nav.Link style={style.navLink} href="/aspa">
                  SÖK MK
                </Nav.Link>
                <Nav.Link style={style.navLink} href="/kontakt">
                  FÖRETAG
                </Nav.Link>
                {/* <Nav.Link style={style.navLink} href="/Pateter">PATETER</Nav.Link> */}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      );
    }
  }
}

const headerText = {
  fontSize: "2.5vh",
  marginLeft: "10px",
};
const smallHeaderText = {
  fontSize: "2.0vh",
  marginLeft: "60px",
};

const smallLogo = {
  position: "absolute",
  top: "0",
  left: "0",
  width: "15%",
  height: "70px",
  borderRadius: "110%",
};

const style = {
  header: {
    backgroundColor: "#034169",
    color: "#000000",
    textAlign: "right",
    verticalAlign: "middle",
  },

  logo: {
    position: "absolute",
    top: "0",
    left: "0",
    height: "70px",
    width: "80px",
  },
  logoImg: {
    height: "100%",
  },
  navLink: {
    marginRight: "5px",
    color: "white",
  },
};
export default Header;
