import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import ReceptionContest from "./components/pages/ReceptionContest";
import Container from "react-bootstrap/Container";
import Home from "./components/pages/Home";
import FirstDay from "./components/pages/FirstDay";
import Pateter from "./components/pages/Pateter";
import About from "./components/pages/About";
import NollK from "./components/pages/NollK";
import Kontakt from "./components/pages/Kontakt";
import Aspa from "./components/pages/Aspa";
import "./index.css";

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Header />
        <Container style={contentStyle}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/first_day" component={FirstDay} />
            <Route path="/reception_contest" component={ReceptionContest} />
            <Route path="/arrangemang" component={ReceptionContest} />
            <Route path="/pateter" component={Pateter} />
            <Route path="/about" component={About} />
            <Route path="/nollk" component={NollK} />
            <Route path="/kontakt" component={Kontakt} />
            <Route path="/aspa" component={Aspa} />
            <Route path="*" status={404} component={NoMatch} />
          </Switch>
          <div style={contentWrap}></div>
          <Footer />
        </Container>
      </BrowserRouter>
    </React.Fragment>
  );
}
const contentStyle = {
  position: " relative",
  minHeight: "100vh",
  maxWidth: "100vw",
  overflow: "hidden",
  top: "56px",
};
const contentWrap = {
  paddingBottom: "124px",
};

function NoMatch() {
  return (
    <Container>
      <div style={{ marginTop: "50px" }}>
        <h1>
          404 - Page not found. Gå till <a href="/">startsidan</a>
        </h1>
      </div>
    </Container>
  );
}

export default App;
