import React, { Component } from "react";
import { Card } from "react-bootstrap";
import { text1 } from "../style.js";

class NollKCard extends Component {
  render() {
    return (
      <Card style={cardStyle}>
        <Card.Body>
          <Card.Title>
            <Card.Link style={text1} href={this.props.Web}>
              {this.props.Title}
            </Card.Link>
          </Card.Title>
          <Card.Text>{this.props.Program}</Card.Text>
        </Card.Body>
        <Card.Link href={this.props.Web}>
          <Card.Img
            src={this.props.ImageSrc}
            alt={this.props.Title}
            style={{ maxHeight: "200px", maxWidth: "200px" }}
          />
        </Card.Link>
      </Card>
    );
  }
}

const cardStyle = {
  border: "none",
  // borderRadius: "999em",
  // minHeight: "240px",
  paddingRight: "20px",
  // boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
  whiteSpace: "pre-line",
  // '&:hover': {
  //   boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
  // }
};

export default NollKCard;
