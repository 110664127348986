export const titel = {
  fontSize: "1.75em",
  marginBottom: "5px",
};

export const text1 = {
  color: "black",
  fontSize: "1.8rem",
};

export const text2 = {
  textAlign: "justify",
};

export const divider = {
  backgroundColor: "black",
  width: "100%",
  height: "2px",
  marginBottom: "30px",
  display: "flex",
  justifyContent: "center",
};

export const flexCenter = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const linkStyle = {
  textDecoration: "underline",
  display: "inline",
  padding: 0,
  color: "black",
  fontWeight: 500,
};

// .titel {
//   font-size: "2.8rem";
//   margin-bottom: "5px";
// };

// .text1 {
//   font-size: "2.4em",
// }

// .text2 {
//   font-size: "1.2rem";
// }

// .flexCenter {
//   display: "flex";
//   justify-content: "center";
//   align-items: "center";
// }
