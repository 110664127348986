import React, { Component } from "react";
import ChalmersLogo from "../../img/ChalmersLogo.png";
import CFFC from "../../img/cffc.jpg";
import { Image, Container } from "react-bootstrap";

class Footer extends Component {
  render() {
    return (
      <Container style={footerStyle}>
        <div>
          <a
            href="https://chalmersstudentkar.se"
            target="_blank"
            rel="noreferrer"
            style={{ float: "left" }}
          >
            <Image alt="Chalmers Studentkår" height={60} src={ChalmersLogo} />
          </a>
        </div>
        {/* <a href="mailto:info@mk.chs.chalmers.se" style={centerLink}>
          info@mk.chs.chalmers.se
        </a> */}
        <div>
          <a
            href="http://cffc.se"
            target="_blank"
            rel="noreferrer"
            style={{ float: "right" }}
          >
            <Image alt="CFFC" height={60} src={CFFC} />
          </a>
        </div>
      </Container>
    );
  }
}

const centerLink = {
  lineHeight: "68px",
  textAlign: "center",
};

const footerStyle = {
  height: "68px",
  backgroundColor: "#FFFFFF",
  borderTop: "2px solid",
  gridTemplateColumns: "1fr 1fr",
  display: "grid",
};

export default Footer;
