import React, { Component } from "react";
import YouTube from "react-youtube";
import Container from "react-bootstrap/Container";

class FirstDay extends Component {
  render() {
    const opts = {
      height: "480",
      width: "860",
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
      },
    };

    return (
      <Container style={videoStyle}>
        <YouTube videoId="TWIon1FiOAs" opts={opts} onReady={this._onReady} />
      </Container>
    );
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.playVideo();
  }
}

const videoStyle = {
  position: "absolute",
  width: "auto",
  height: "auto",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
};

export default FirstDay;
