import React, { Component } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";

class ImageCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
  }

  toggleHover = () => {
    this.setState({ hover: !this.state.hover });
  };

  render() {
    function Content(props) {
      if (!props.isHovered) {
        return (
          <Card style={style.card}>
            <Card.Img src={props.ImageSrc} />
            <Card.ImgOverlay>
              <Container style={style.overlay}>
                <Container style={style.overlayBackground}></Container>
                <Card.Title style={style.cardTitle}>{props.Title}</Card.Title>
              </Container>
            </Card.ImgOverlay>
          </Card>
        );
      } else {
        return (
          <Card style={style.card}>
            <Card.Img src={props.ImageSrc} />
            <Container style={style.hoverCard}></Container>
            <Container style={style.hoverContent}>
              <Row>
                <Col>
                  <h3>{props.Title}</h3>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>
                    <h>{props.Desc}</h>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div style={{ borderBottom: "1px solid white" }}></div>
                </Col>
              </Row>
            </Container>
          </Card>
        );
      }
    }
    return (
      <Container style={{ padding: "0" }}>
        <Content
          style={{ position: "absolute" }}
          ImageSrc={this.props.ImageSrc}
          Title={this.props.Title}
          Desc={this.props.Desc}
          isHovered={this.state.hover}
        />
        <Container
          style={style.hoverTrigger}
          onMouseEnter={this.toggleHover}
          onMouseOut={this.toggleHover}
        ></Container>
      </Container>
    );
  }
}

const style = {
  overlay: {
    position: "absolute",
    width: "80%",
    height: "20%",
    left: "0px",
    bottom: "10%",
    padding: "0",
  },
  overlayBackground: {
    position: "absolute",
    backgroundColor: "#034169",
    opacity: "0.75",
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
    width: "100%",
    height: "100%",
  },
  cardTitle: {
    color: "white",
    fontSize: "2vh",
    position: "absolute",
    marginLeft: "10px",
    top: "50%",
    transform: "translate(0, -50%)",
  },
  hoverTrigger: {
    position: "absolute",
    top: "0",
    left: "0",
    height: "100%",
    width: "100%",
  },
  hoverCard: {
    position: "absolute",
    borderRadius: "5px",
    opacity: "0.75",
    width: "100%",
    height: "100%",
    backgroundColor: "#034169",
  },
  hoverContent: {
    position: "absolute",
    color: "white",
    padding: "24px",
  },
  card: {
    border: "none",
    textAlign: "justify",
  },
  link: {
    top: "10",
  },
};

export default ImageCard;
