import React, { Component } from "react";
import BackgroundImage from "../../img/ML_notext.png";
import HeaderImage from "../../img/gotaplatsen.jpg";
import FirstImage from "../../img/MK_logo_color.png";
import SecondImage from "../../img/tekno.jpg";
import TelefonImage from "../../img/telefon.png";
import { Image, Container, Row, Col, Nav } from "react-bootstrap";
import Countdown from "react-countdown";
import { titel, text2, flexCenter, linkStyle } from "../../style.js";

class Home extends Component {
  render() {
    let width = window.innerWidth;
    if (width < 600) {
      return (
        <Container>
          <Image fluid src={BackgroundImage} style={bgStyle}></Image>
          <div style={invisibleWrap}>
            <Image fluid src={HeaderImage} />
          </div>
          <div style={headerStyle}>
            <Image fluid src={HeaderImage} />
          </div>
          <Container>
            <Row style={flexColumn}>
              <h1
                style={{
                  marginTop: "20px",
                  marginBottom: "0px",
                  fontWeight: "bold",
                  letterSpacing: "5px",
                  textIndent: "-5px",
                }}
              >
                <Countdown date={new Date(2024, 7, 20, 8, 30)} />
              </h1>
              <p> tills vi ses på Götaplatsen!</p>
              {/* <h1>
                Mottagningen
              </h1>
              <p> har börjat!</p> */}
            </Row>
            <Row>
              <h1 style={titel}>CHALMERS MOTTAGNING</h1>
            </Row>
            <Row style={flexCenter}>
              <Image style={{ width: "50%" }} src={FirstImage} />
            </Row>
            <Row style={text2}>
              <p>
                  Chalmers Studentkårs mottagning är en av de bästa i Sverige
                  och den är till för att välkomna de nyantagna studenterna till
                  Chalmers på bästa möjliga sätt. Mottagningen sträcker sig över
                  fyra veckor med start den 20 augusti, och under denna tid får 
                  de nya teknologerna under olika former av arrangemang lära känna 
                  sin klass, lära sig att hitta på campus och i Göteborg, lära sig 
                  mer om sin sektion och kåren, få hjälp med att komma in i studierna 
                  och mycket mer.
              </p>
              <p>
                Alla aktiviteter under mottagningsveckorna är frivilliga och man
                väljer helt själv hur mycket eller hur lite man vill vara med
                på. Mottagningen är som sagt till för de nya studenterna och för
                att de ska få en så bra start som möjligt på sin nya tid som
                chalmerister.
              </p>
              <p>
                Mycket av det som händer under mottagningen sker på
                sektionsnivå, men det finns även centrala arrangemang då
                nyantagna från hela Chalmers är med.
              </p>
            </Row>
            <Row style={text2}>
              <h3 style={titel}>Välkommen till första dagen på Chalmers!</h3>
            </Row>
            <Row>
              <Image
                style={{
                  borderRadius: "1%",
                  marginBottom: "10px",
                  width: "100%",
                  height: "100%",
                }}
                fluid
                src={SecondImage}
              />
            </Row>
            <Row style={text2}>
              <p>
                Första Dagen på Chalmers samlas alla nya studenter på
                Götaplatsen där de blir mottagna av representanter från deras
                sektion. Första Dagen präglas Götaplatsen av ett hav av färger,
                glada människor och över 3000 nya studenter som är redo att bli
                en del av Chalmers gemenskap. I år äger första dagen rum <b>den 20 augusti</b>.
              </p>
              <p>
                Efter Götaplatsen går en parad med alla studenter upp till
                Chalmers och Teknologgården. Där får de lyssna på inspirerande
                tal från Rektorn, Kårordförande och Mottagningskommitténs
                ordförande. Allt detta är gjort för att skapa en gemenskap som
                kommer hålla livet ut för alla Chalmerister.
              </p>
              <p>
                Under Första dagen kommer de nyantagna studenterna även att få
                umgås mycket med sin nya sektion och lära känna den. De kommer
                även att få träffa de som kommer att ta hand om dem under
                mottagningen, sin NollKommitté och Phaddrar. Länkar till
                NollKommittéernas hemsidor finns under fliken{" "}
                <Nav.Link style={linkStyle} href="/NollK">
                  NOLLK.
                </Nav.Link>
              </p>
            </Row>
            <Row>
              <h3 style={titel}>Trygghetsjouren</h3>
            </Row>
            <Row>
              <Col style={flexWrapperColumn}>
                <Image style={{ width: "50%" }} fluid src={TelefonImage} />
                <a style={mobileNumber} href="tel:+46317723927">
                  031-772&nbsp;39&nbsp;27
                </a>
              </Col>
            </Row>
            <Row lg={2} md={2} sm={1} xs={1} style={rowStyle}>
              <Col style={text2}>
                <p>
                  Under mottagningen finns en jour dit du som ny student kan
                  ringa dygnet runt om du behöver hjälp med att hitta rätt väg,
                  vill ha hjälp med att ringa en taxi, vill ha någon att prata
                  med när du går hem, eller känner dig otrygg. Numret ser du här
                  till höger, så lägg in det i mobilen redan nu!
                </p>
              </Col>
            </Row>
          </Container>
        </Container>
      );
    } else {
      return (
        <Container>
          <Image fluid src={BackgroundImage} style={bgStyle}></Image>
          <div style={invisibleWrap}>
            <Image fluid src={HeaderImage} />
          </div>
          <div style={headerStyle}>
            <Image fluid src={HeaderImage} />
          </div>
          <Container>
            <Row style={flexColumn}>
              <div
                style={{
                  fontSize: "4rem",
                  marginBottom: "-1.5rem",
                  fontWeight: "bold",
                  letterSpacing: "10px",
                  textIndent: "-10px",
                }}
              >
                <Countdown date={new Date(2024, 7, 20, 8, 30)} />
              </div>
              <p style={{ fontSize: "1.5rem", marginBottom: "30px" }}>
                {" "}
                tills vi ses på Götaplatsen!
              </p>
              {/* <div style={{
                  fontSize: "4rem",
                  marginBottom: "-1.0rem",
                  fontWeight: "bold",
                  letterSpacing: "10px",
                  textIndent: "-10px",
                }}
                >
                  Mottagningen
              </div>
              <p style={{ fontSize: "1.5rem", marginBottom: "30px" }}>
                {" "}
                har börjat!
              </p> */}
            </Row>
            <Row lg={2} md={2} sm={1} xs={1} style={rowStyle}>
              <Col style={text2}>
                <h1 style={titel}>CHALMERS MOTTAGNING</h1>
                <p>
                  Chalmers Studentkårs mottagning är en av de bästa i Sverige
                  och den är till för att välkomna de nyantagna studenterna till
                  Chalmers på bästa möjliga sätt. Mottagningen sträcker sig över
                  fyra veckor med start den 20 augusti, och under denna tid får 
                  de nya teknologerna under olika former av arrangemang lära känna 
                  sin klass, lära sig att hitta på campus och i Göteborg, lära sig 
                  mer om sin sektion och kåren, få hjälp med att komma in i studierna 
                  och mycket mer.
                </p>
                <p>
                  Alla aktiviteter under mottagningsveckorna är frivilliga och
                  man väljer helt själv hur mycket eller hur lite man vill vara
                  med på. Mottagningen är som sagt till för de nya studenterna
                  och för att de ska få en så bra start som möjligt på sin nya
                  tid som chalmerister.
                </p>
                <p>
                  Mycket av det som händer under mottagningen sker på
                  sektionsnivå, men det finns även centrala arrangemang då
                  nyantagna från hela Chalmers är med.
                </p>
              </Col>
              <Col style={flexCenter}>
                <Image fluid src={FirstImage} />
              </Col>
            </Row>
            <Row lg={2} md={2} sm={1} xs={1} style={rowStyle}>
              <Col>
                <Image style={secondImage} fluid src={SecondImage} />
              </Col>
              <Col style={text2}>
                <h3 style={titel}>Välkommen till första dagen på Chalmers!</h3>
                <p>
                  Första Dagen på Chalmers samlas alla nya studenter på
                  Götaplatsen där de blir mottagna av representanter från deras
                  sektion. Första Dagen präglas Götaplatsen av ett hav av
                  färger, glada människor och över 3000 nya studenter som är
                  redo att bli en del av Chalmers gemenskap. I år äger första dagen rum <b>den 20 augusti</b>.
                </p>
                <p>
                  Efter Götaplatsen går en parad med alla studenter upp till
                  Chalmers och Teknologgården. Där får de lyssna på inspirerande
                  tal från Rektorn, Kårordförande och Mottagningskommitténs
                  ordförande. Allt detta är gjort för att skapa en gemenskap som
                  kommer hålla livet ut för alla Chalmerister.
                </p>
                <p>
                  Under Första dagen kommer de nyantagna studenterna även att få
                  umgås mycket med sin nya sektion och lära känna den. De kommer
                  även att få träffa de som kommer att ta hand om dem under
                  mottagningen, sin NollKommitté och Phaddrar. Länkar till
                  NollKommittéernas hemsidor finns under fliken{" "}
                  <Nav.Link style={linkStyle} href="/NollK">
                    NOLLK.
                  </Nav.Link>
                </p>
              </Col>
            </Row>
            <Row lg={2} md={2} sm={1} xs={1} style={rowStyle}>
              <Col style={text2}>
                <h3 style={titel}>Trygghetsjouren</h3>
                <p>
                  Under mottagningen finns en jour dit du som ny student kan
                  ringa dygnet runt om du behöver hjälp med att hitta rätt väg,
                  vill ha hjälp med att ringa en taxi, vill ha någon att prata
                  med när du går hem, eller känner dig otrygg. Numret ser du här
                  till höger, så lägg in det i mobilen redan nu!
                </p>
              </Col>
              <Col style={flexWrapperColumn}>
                <Image style={{ width: "60%" }} fluid src={TelefonImage} />
                <a style={mobileNumber} href="tel:+46317723927">
                  031-772&nbsp;39&nbsp;27
                </a>
              </Col>
            </Row>
          </Container>
        </Container>
      );
    }
  }
}
// Styles
const rowStyle = {
  marginBottom: "100px",
  alignItems: "center",
};
const invisibleWrap = {
  position: "relative",
  left: "0",
  width: "100%",
  height: "auto",
  overflow: "hidden",
  top: "0",
  marginBottom: "10vw",
};
const headerStyle = {
  position: "absolute",
  left: "0",
  width: "100%",
  height: "580px",
  overflow: "hidden",
  top: "0",
};
const bgStyle = {
  position: "absolute",
  transform: "translate(-50%, 0)",
  left: "50%",
  top: "425px",
  opacity: "0.1",
};

const secondImage = {
  borderRadius: "1%",
  marginBottom: "10px",
};

const flexWrapperColumn = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const mobileNumber = {
  color: "black",
  margin: "0",
  padding: "0",
  fontSize: "4vmax",
  fontWeight: "bold",
};

const flexColumn = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

export default Home;
