import React, { Component } from "react";
import { Nav, Row, Col, Container, Image } from "react-bootstrap";
// import MKlogo from "../../img/MK_logo_color.png";
import MKul from "../../img/MKul24.jpg";
import { titel, text1, text2, flexCenter, linkStyle } from "../../style.js";

import Aspschema1 from "../../img/aspschema1.png";
import Aspschema2 from "../../img/aspschema2.png";

class Aspa extends Component {
  render() {
    return (
      <Container>
        <Row lg={2} md={2} sm={1} xs={1} style={{ marginTop: "50px" }}>
          <Col style={{ height: "100%" }}>
            <h1 style={titel}>SÖK CHALMERS MOTTAGNINGSKOMMITTÉ</h1>
            <div style={text2}>
              <p>
                Under ett år arbetar MK ideellt för planera och arrangera
                mottagningen centralt, och detta arbete startas 1 januari varje
                år. Att söka MK är öppet för vem som helst inom Chalmers
                studentkår, och för att få kolla på vad arbetet innebär hålls
                därför varje år en aspning där man får möjlighet att testa på
                vårt arbete. Efter aspningen är man sedan varmt välkommen att
                söka MK.
              </p>
              <p>
                Aspningen börjar vanligtvis i slutet på LP1 och pågår till runt
                mitten av LP2. Under aspningen får man testa på allt från att
                arrangera sittningar och aktiviteter, till att diskutera
                situationer som kan uppstå under mottagningen, eller bara umgås
                med de andra asparna.
              </p>
              <p>
                Är du intresserad av att veta mer om vad vi gör och vilka vi är
                så kan du spana in de olika posterna i MK på fliken:{" "}
                <Nav.Link style={linkStyle} href="/about">
                  OM MK.
                </Nav.Link>
              </p>
            </div>
          </Col>
          <Col style={flexCenter}>
            <img src={MKul} style={{ width: "100%" }} alt={"MK har kul"}></img>
          </Col>
        </Row>
        <Row style={topMargin}>
          <div style={text1}>ASPSCHEMA</div>
        </Row>
        <Row>
          <div style={divider}></div>
        </Row>
        <Row style={topMargin}></Row>
        <Row>
          <Image src={Aspschema1} style={imgStyle} alt={""} ></Image>
          {/*<Image src={Aspschema1} style={imgStyle} alt={""} ></Image>*/}
        </Row>
        <Row>
          <Image src={Aspschema2} style={imgStyle} alt={""} ></Image>
          {/*<Image src={Aspschema2} style={imgStyle} alt={""} ></Image>*/}
        </Row>
      </Container>
    );
  }
}

const divider = {
  backgroundColor: "black",
  width: "100%",
  height: "2px",
  padding: "0",
  position: "relative",
};

const topMargin = {
  marginTop: "50px",
};


const imgStyle = {
  width: "84%",
  borderRadius: "1%",
  position: "relative",
  left: "8%",
  marginBottom: "10px",
  border: "1px solid black",
};

export default Aspa;
